<template>
  <div class="chat-container">
    <div class="chat-top">
      <HotQuestion
          @clickChatTheme="clickChatTheme"
          v-if="!openChatPanel"
      />
      <ChatPanel :chatData="chatData" v-else/>
    </div>

    <SendTextContent
        :modelName.sync="model"
        @addChatTheme="addChatTheme"
        @sendMessage="sendMessage"
    />
  </div>
</template>
<script>

import {generateUUID} from "ant-design-vue/lib/vc-select/util";
import {currentTime} from "@/utils/DateUtil";
import {EventSourcePolyfill} from "event-source-polyfill";
import {APICONFIG} from "@/utils/apiConfig";
import {chat} from "@/api/chat";

const defaultMessage = `<div><a-icon type="sync" spin />AI思考中... </div>`
export default ({
  components: {
    HotQuestion: () => import('@/views/application/chat/components/HotQuestion.vue'),
    SendTextContent: () => import('@/views/application/chat/components/SendTextContent.vue'),
    ChatPanel: () => import('@/views/application/chat/components/ChatPanel.vue'),
  },
  data() {
    return {
      openChatPanel: false,
      chatUid: '',
      chatData: [],
      sse: null,
      model: 'gpt3'
    }
  },
  methods: {
    clickChatTheme(message) {
      // this.$message.warning(message)
      // this.openChatPanel = true
      this.sendMessage(message, this.model)
    },
    addChatTheme() {
      this.openChatPanel = false
      // this.$message.warning('addChatTheme')
    },

    getServeRes(text) {
      if (this.chatData) {
        let cur = this.chatData[this.chatData.length - 1]
        //当前批次存在服务端返回消息，流式
        if (cur.left.text) {
          let resData = cur.left.text.indexOf(defaultMessage) >= 0 ? text : cur.left.text + text
          return {
            dateTime: currentTime(),
            text: resData,
          }
          // return {
          //   dateTime: currentTime(),
          //   text: cur.left.text + text,
          // }
        } else {
          return {
            dateTime: currentTime(),
            text,
          }
        }
      }
    },
    // 发送消息，点击【发送】按钮
    sendMessage(message, model) {

      this.model = model
      //新的对话
      if (!this.openChatPanel) {
        this.openChatPanel = true
        this.chatUid = generateUUID()
        this.chatData = []
        this.buildMessage(message)
      } else {
        //已有对话
        this.buildMessage(message)
      }
      this.createSse()
      this.scrollToBottom()
    },
    buildMessage(message) {
      this.chatData.push({
        chatUid: this.chatUid,
        right: {
          text: message,
          dateTime: currentTime(),
        }
      })
      chat(this.chatUid, message, this.model).then(res => {
        if (res.code === -1) {
          // this.$message.warn(res.message)
          let cur = this.chatData[this.chatData.length - 1]
          this.$set(cur, "left", {
            text: res.message,
            dateTime: currentTime()
          })
        } else {
          this.chatData.push({
            left: {
              text: defaultMessage,
              dateTime: currentTime()
            }
          })
        }
        // this.spinning = false
      }).finally(() => {
        // this.spinning = false
      })
    },
    //设置滚动条到底部
    scrollToBottom() {
      this.$nextTick(() => {
        let container = this.$el.querySelector(".chat-top");
        container.scrollTop = container.scrollHeight;
      });
    },

    createSse() {
      if (window.EventSource) {
        const cc = localStorage.getItem("cc")
        if (!cc) {
          localStorage.setItem("cc", generateUUID())
        }
        const eventSource = new EventSourcePolyfill(`${APICONFIG}/createSse`, {
          headers: {
            uid: this.chatUid,
            'X-model-name': this.model,
            'X-consume-num': 1,
            'X-client-code': localStorage.getItem("cc"),
            'X-Token': this.$store.getters.token
          }
        });
        eventSource.onmessage = (event) => {
          if (event.lastEventId === "[TOKENS]") {
          }
          if (event.data === "[DONE]") {
            if (this.sse) {
              this.sse.close();
            }
            return;
          }
          let json_data = JSON.parse(event.data)
          if (json_data.content == null || json_data.content === 'null') {
            return;
          }
          const res = this.getServeRes(json_data.content)
          if (res) {
            let cur = this.chatData[this.chatData.length - 1]
            this.$set(cur, "left", res)
          }
          this.scrollToBottom()
        }
        eventSource.onopen = (event) => {
          this.sse = event.target;
        };
        eventSource.onerror = (event) => {
          if (this.sse) {
            this.sse.close();
          }
        };
        eventSource.close = (event) => {
          console.log("close :" + 44 + ": ", event)
        };
      }
    }
  }

})
</script>

<style scoped lang="scss">
.chat-container {
  display: flex;
  height: 100%;
  flex-direction: column;

  .chat-top {
    height: calc(100vh - 200px);
    overflow-y: auto;
  }
}
</style>